/* eslint-env browser */
import React from "react";

import {useLocalStorage} from "@tzmedical/react-hooks";

function useArrayReducer(localStorageFilters = {storageKey: ""}) {
  const [filtersFromStorage] = useLocalStorage(localStorageFilters.storageKey, null);

  return React.useCallback(
    (elements, action) => {
      switch (action.type) {
        case "init": {
          if (!Array.isArray(action.elements)) {
            throw Error("Elements must be initialized as an array");
          }

          return action.elements;
        }

        case "created": {
          if (!action.newElement || typeof action.newElement !== "object") {
            throw Error("Missing new element object");
          }

          return [...elements, action.newElement];
        }

        case "updated": {
          if (
            !action.updatedElement ||
            typeof action.updatedElement !== "object" ||
            !action.updatedElement?.id
          ) {
            throw Error("Missing updated element object");
          }

          let matchesFilterSelection = true;
          // this needs to be compared to false in case the value returned is undefined
          if (
            filtersFromStorage &&
            filtersFromStorage[action.updatedElement[localStorageFilters.filterBy]] === false
          ) {
            matchesFilterSelection = false;
          }

          const updatedElementArray = [];
          elements.forEach((element) => {
            if (element.id === action.updatedElement.id) {
              if (matchesFilterSelection) {
                updatedElementArray.push(action.updatedElement);
              }
            } else {
              updatedElementArray.push(element);
            }
          });

          return updatedElementArray;
        }

        case "deleted": {
          if (!action.id) {
            throw Error("Missing ID of deleted element");
          }

          return elements.filter((element) => element.id !== action.id);
        }

        default: {
          throw Error(`Unknown action: ${action.type}`);
        }
      }
    },
    [localStorageFilters, filtersFromStorage]
  );
}

export default useArrayReducer;

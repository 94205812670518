import angular from "angular";

import ecgEventItem from "./ecgEventItem/index.js";
import notificationItem from "./notificationItem/index.js";
import reportItem from "./reportItem/index.js";
import unopenableItem from "./unopenableItem/index.js";
import inboxItemComponent from "./inboxItem.component.js";
import InboxItemController from "./inboxItem.controller.js";

export default angular
  .module("app.components.inboxItem", [ecgEventItem, unopenableItem, notificationItem, reportItem])
  // matches <br-inbox-item>
  .component("brInboxItem", inboxItemComponent)
  .controller("InboxItemController", InboxItemController).name;

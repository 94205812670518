import pugFile from "./inboxItem.pug";

export default {
  bindings: {
    item: "=",
    selectedItem: "=",
    isNextAvailable: "=",
  },
  controller: "InboxItemController",
  template: pugFile(),
};

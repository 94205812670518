import React from "react";

// Export the object too, for Angular code
export const userReadableRoles = {
  facilityAdmin: "Facility Admin",
  tzAdmin: "TZ Admin",
  warehouse: "TZ Warehouse",
  tech: "ECG Technician",
  triageTech: "Triage Technician",
  physician: "Physician",
  clinicalStaff: "Clinical Staff",
};

function useUserReadableRoles() {
  return React.useMemo(() => userReadableRoles, []);
}

export default useUserReadableRoles;

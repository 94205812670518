import angular from "angular";

import unopenableItemComponent from "./unopenableItem.component.js";
import UnopenableItemController from "./unopenableItem.controller.js";

export default angular
  .module("app.components.inboxItem.unopenableItem", [])
  // matches <br-unopenable-item>
  .component("brUnopenableItem", unopenableItemComponent)
  .controller("UnopenableItemController", UnopenableItemController).name;

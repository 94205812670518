/* eslint-env browser */

//------------------------------------------------------------------------------
//             __             __   ___  __
//     | |\ | /  ` |    |  | |  \ |__  /__`
//     | | \| \__, |___ \__/ |__/ |___ .__/
//
//------------------------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import UserClassificationChip from "./UserClassificationChip.jsx";

// =============================================================================
function UserClassificationChipWrapper({id, disabled, user, algorithm}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const userClassification = user;
  const algorithmClassification = algorithm;

  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from UserClassificationChip.jsx is because of Angular.
  //  Once we switch completely from Angular to React, ConfirmProvider can be moved to an app.js so
  //  that it encapsulates the entire application. Then these two files can be combined.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <UserClassificationChip
        itemId={id}
        disabled={disabled}
        userClassification={userClassification}
        algorithmClassification={algorithmClassification}
      />
    </AddProviders>
  );
}

UserClassificationChipWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  algorithm: PropTypes.string.isRequired,
};

export default UserClassificationChipWrapper;

import pugFile from "./unopenableItem.pug";

export default {
  controller: "UnopenableItemController",
  bindings: {
    item: "=",
    selectedItem: "=",
    lockedByUser: "=",
    unopenableType: "@",
  },
  template: pugFile(),
};

import angular from "angular";

import notificationItemComponent from "./notificationItem.component.js";
import NotificationItemController from "./notificationItem.controller.js";

export default angular
  .module("app.components.inboxItem.notificationItem", [])
  // matches <br-notification-item>
  .component("brNotificationItem", notificationItemComponent)
  .controller("NotificationItemController", NotificationItemController).name;

/* eslint-env browser */
import React from "react";
import {zxcvbn, zxcvbnOptions} from "@zxcvbn-ts/core";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";

//---------------------------------------------------------------------------
// zxcvbn is used for checking password strength
//---------------------------------------------------------------------------
zxcvbnOptions.setOptions({
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  translations: zxcvbnEnPackage.translations,
});
const MIN_STRENGTH = 1;
const MATCH_SIZE = 4;
const SEQ_UP = "0123456789";
const SEQ_DOWN = "9876543210";

const useValidatePasswordStrength = (setScore, setSuggestionList) => {
  return React.useCallback(
    (value) => {
      // Since zxcvbn allows sequential numbers (1234567891011121314), ensure the user does not
      // enter sequences of numbers up or down greater than 4 in a row. Since regular expressions
      // are poorly suited to this, try to map each 4 characters of the input into a sequential
      // list. If there is no match, it's passes. If it matches into the sequential string, it fails.
      let i;
      for (i = 0; i < value.length + 1 - MATCH_SIZE; i++) {
        const chunk = value.substring(i, i + MATCH_SIZE);
        // if it maps into an ascending or descending list, that's a lame password.
        if (SEQ_UP.indexOf(chunk) !== -1 || SEQ_DOWN.indexOf(chunk) !== -1) {
          return "Please avoid using sequential numbers in your password.";
        }
      }

      const {score, feedback} = zxcvbn(value);
      setScore((100 * (score + 1)) / 5);
      setSuggestionList(feedback.suggestions);
      if (score < MIN_STRENGTH || feedback.warning) {
        return feedback.warning || "Please choose a stronger password";
      }
      return true;
    },
    [setScore, setSuggestionList]
  );
};

export default useValidatePasswordStrength;

import pugFile from "./notificationItem.pug";

export default {
  controller: "NotificationItemController",
  bindings: {
    item: "=",
    selectedItem: "=",
  },
  template: pugFile(),
};

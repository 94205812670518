/* eslint-env browser */
import React from "react";
import {darken, lighten} from "polished";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Bolt from "@mui/icons-material/Bolt";
import PersonAdd from "@mui/icons-material/PersonAddAlt1";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Chip from "@mui/material/Chip";
import {grey, purple} from "@mui/material/colors";
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";

function UserClassificationChip({
  // Props
  itemId,
  disabled,
  userClassification,
  algorithmClassification,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const theme = useTheme();
  const {features} = useEnvironmentVariables();

  const {label, color, backgroundColor, icon} = React.useMemo(() => {
    let chipColor = darken(0.1, theme.palette.secondary.main);
    let backgroundChipColor = lighten(0.35, theme.palette.secondary.main);

    if (algorithmClassification === "autoClassified" && features.algorithmClassification) {
      ({500: chipColor} = purple);
      backgroundChipColor = lighten(0.5, purple[500]);
    }

    // if disabled, overwrite with disabled coloring
    if (disabled) {
      chipColor = darken(0.3, grey[500]);
      backgroundChipColor = lighten(0.25, grey[500]);
    }

    const chipAttributes = {
      label: userClassification,
      color: chipColor,
      backgroundColor: backgroundChipColor,
      icon: <PersonAdd sx={{paddingLeft: 0.5}} color={chipColor} />, // Fallback
    };

    if (algorithmClassification === "autoClassified") {
      chipAttributes.icon = <Bolt sx={{paddingLeft: 0.5}} color={chipColor} />;
    }

    return chipAttributes;
  }, [userClassification, algorithmClassification, disabled, theme, features.algorithmClassification]);

  return (
    <Chip
      label={label}
      size="small"
      variant="outlined"
      sx={{color, borderColor: color, backgroundColor, marginLeft: 1}}
      data-cy={`${itemId}-classification-chip`}
      disabled={disabled}
      icon={icon}
    />
  );
}

UserClassificationChip.propTypes = {
  itemId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  userClassification: PropTypes.string.isRequired,
  algorithmClassification: PropTypes.string.isRequired,
};

export default UserClassificationChip;

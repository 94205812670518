import angular from "angular";

import reportArrhythmiaTimelineFooterPug from "./reportArrhythmiaTimelineFooter.pug";
import reportArrhythmiaTimelineHeaderPug from "./reportArrhythmiaTimelineHeader.pug";
import reportArrhythmiaTimelineSectionPug from "./reportArrhythmiaTimelineSection.pug";
import reportDonutChartsPug from "./reportDonutCharts.pug";
import reportESignPhysicianCommentsPug from "./reportESignPhysicianComments.pug";
import reportEventIndexFooterPug from "./reportEventIndexFooter.pug";
import reportEventIndexHeaderPug from "./reportEventIndexHeader.pug";
import reportEventIndexItemPug from "./reportEventIndexItem.pug";
import reportFooterPug from "./reportFooter.pug";
import reportHeaderPug from "./reportHeader.pug";
import reportHeartRateSummaryPug from "./reportHeartRateSummary.pug";
import reportHeightPlaceholderPug from "./reportHeightPlaceholder.pug";
import reportHrTrendFooterPug from "./reportHrTrendFooter.pug";
import reportHrTrendHeaderPug from "./reportHrTrendHeader.pug";
import reportHrTrendSectionPug from "./reportHrTrendSection.pug";
import reportPhysicianCommentsPug from "./reportPhysicianComments.pug";
import reportPvcBurdenFooterPug from "./reportPvcBurdenFooter.pug";
import reportPvcBurdenHeaderPug from "./reportPvcBurdenHeader.pug";
import reportPvcBurdenSectionPug from "./reportPvcBurdenSection.pug";
import reportStripFooterPug from "./reportStripFooter.pug";
import reportStripHeaderPug from "./reportStripHeader.pug";
import reportStripSegmentPug from "./reportStripSegment.pug";
import reportSummaryPug from "./reportSummary.pug";
import reportTechnicianFindingsPug from "./reportTechnicianFindings.pug";
import reportVerticalSpacerPug from "./reportVerticalSpacer.pug";

export default angular
  .module("app.components.generatedReportElements", [])
  .directive("brReportFooter", [reportFooterDirective])
  .directive("brReportHeader", [reportHeaderDirective])
  .directive("brReportPhysicianComments", [reportPhysicianCommentsDirective])
  .directive("brReportESignPhysicianComments", [reportESignPhysicianCommentsDirective])
  .directive("brReportTechnicianFindings", [reportTechnicianFindingsDirective])
  .directive("brReportStripFooter", [reportStripFooterDirective])
  .directive("brReportStripHeader", [reportStripHeaderDirective])
  .directive("brReportStripSegment", [reportStripSegmentDirective])
  .directive("brReportSummary", [reportSummaryDirective])
  .directive("brReportEventIndexHeader", [reportEventIndexHeaderDirective])
  .directive("brReportEventIndexItem", [reportEventIndexItemDirective])
  .directive("brReportEventIndexFooter", [reportEventIndexFooterDirective])
  .directive("brReportHeartRateSummary", [reportHeartRateSummaryDirective])
  .directive("brReportDonutCharts", [reportDonutChartsDirective])
  .directive("brReportHrTrendHeader", [reportHrTrendHeaderDirective])
  .directive("brReportHrTrendSection", [reportHrTrendSectionDirective])
  .directive("brReportHrTrendFooter", [reportHrTrendFooterDirective])
  .directive("brReportPvcBurdenHeader", [reportPvcBurdenHeaderDirective])
  .directive("brReportPvcBurdenSection", [reportPvcBurdenSectionDirective])
  .directive("brReportPvcBurdenFooter", [reportPvcBurdenFooterDirective])
  .directive("brReportArrhythmiaTimelineHeader", [reportArrhythmiaTimelineHeaderDirective])
  .directive("brReportArrhythmiaTimelineSection", [reportArrhythmiaTimelineSectionDirective])
  .directive("brReportArrhythmiaTimelineFooter", [reportArrhythmiaTimelineFooterDirective])
  .directive("brReportVerticalSpacer", [reportVerticalSpacerDirective])
  .directive("brReportHeightPlaceholder", [reportHeightPlaceholderDirective]).name;

// matches <br-report-footer>
function reportFooterDirective() {
  return {
    restrict: "E",
    template: reportFooterPug(),
  };
}

// matches <br-report-header>
function reportHeaderDirective() {
  return {
    restrict: "E",
    template: reportHeaderPug(),
  };
}

// matches <br-report-physician-comments>
function reportPhysicianCommentsDirective() {
  return {
    restrict: "E",
    template: reportPhysicianCommentsPug(),
  };
}

// matches <br-report-e-sign-physician-comments>
function reportESignPhysicianCommentsDirective() {
  return {
    restrict: "E",
    template: reportESignPhysicianCommentsPug(),
  };
}

// matches <br-report-technician-findings>
function reportTechnicianFindingsDirective() {
  return {
    restrict: "E",
    template: reportTechnicianFindingsPug(),
  };
}

// matches <br-report-strip-footer>
function reportStripFooterDirective() {
  return {
    restrict: "E",
    template: reportStripFooterPug(),
  };
}

// matches <br-report-strip-header>
function reportStripHeaderDirective() {
  return {
    restrict: "E",
    template: reportStripHeaderPug(),
  };
}

// matches <br-report-strip-segment>
function reportStripSegmentDirective() {
  return {
    restrict: "E",
    template: reportStripSegmentPug(),
  };
}

// matches <br-report-summary>
function reportSummaryDirective() {
  return {
    restrict: "E",
    template: reportSummaryPug(),
  };
}

// matches <br-report-event-index-header>
function reportEventIndexHeaderDirective() {
  return {
    restrict: "E",
    template: reportEventIndexHeaderPug(),
  };
}

// matches <br-report-event-index-item>
function reportEventIndexItemDirective() {
  return {
    restrict: "E",
    template: reportEventIndexItemPug(),
  };
}

// matches <br-report-event-index-footer>
function reportEventIndexFooterDirective() {
  return {
    restrict: "E",
    template: reportEventIndexFooterPug(),
  };
}

// matches <br-report-heart-rate-summary>
function reportHeartRateSummaryDirective() {
  return {
    restrict: "E",
    template: reportHeartRateSummaryPug(),
  };
}

// matches <br-report-donut-charts>
function reportDonutChartsDirective() {
  return {
    restrict: "E",
    template: reportDonutChartsPug(),
  };
}
// matches <br-report-hr-trend-header>
function reportHrTrendHeaderDirective() {
  return {
    restrict: "E",
    template: reportHrTrendHeaderPug(),
  };
}
// matches <br-report-hr-trend-section>
function reportHrTrendSectionDirective() {
  return {
    restrict: "E",
    template: reportHrTrendSectionPug(),
  };
}
// matches <br-report-hr-trend-footer>
function reportHrTrendFooterDirective() {
  return {
    restrict: "E",
    template: reportHrTrendFooterPug(),
  };
}
// matches <br-report-pvc-burden-header>
function reportPvcBurdenHeaderDirective() {
  return {
    restrict: "E",
    template: reportPvcBurdenHeaderPug(),
  };
}
// matches <br-report-pvc-burden-section>
function reportPvcBurdenSectionDirective() {
  return {
    restrict: "E",
    template: reportPvcBurdenSectionPug(),
  };
}
// matches <br-report-pvc-burden-footer>
function reportPvcBurdenFooterDirective() {
  return {
    restrict: "E",
    template: reportPvcBurdenFooterPug(),
  };
}
// matches <br-report-arrhythmia-timeline-header>
function reportArrhythmiaTimelineHeaderDirective() {
  return {
    restrict: "E",
    template: reportArrhythmiaTimelineHeaderPug(),
  };
}
// matches <br-report-arrhythmia-timeline-section>
function reportArrhythmiaTimelineSectionDirective() {
  return {
    restrict: "E",
    template: reportArrhythmiaTimelineSectionPug(),
  };
}
// matches <br-report-arrhythmia-timeline-footer>
function reportArrhythmiaTimelineFooterDirective() {
  return {
    restrict: "E",
    template: reportArrhythmiaTimelineFooterPug(),
  };
}
// matches <br-report-vertical-spacer>
function reportVerticalSpacerDirective() {
  return {
    restrict: "E",
    template: reportVerticalSpacerPug(),
  };
}
// matches <br-report-height-placeholder>
function reportHeightPlaceholderDirective() {
  return {
    restrict: "E",
    template: reportHeightPlaceholderPug(),
  };
}

import pugFile from "./ecgEventItem.pug";

export default {
  controller: "EcgEventItemController",
  bindings: {
    item: "=",
    selectedItem: "=",
    selectedUserRhythmClassification: "=selectedClassification",
    technicianFindings: "=",
    meetsMdnCriteria: "=",
    createdBeatMarkers: "=",
    deletedBeatMarkers: "=",
    editingReport: "=",
    generatedReportId: "=",
    includedStrips: "=",
  },
  template: pugFile(),
};

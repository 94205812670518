/* eslint-env browser */
import React from "react";

export function doesNotMeetExtendedHolterRequirements(
  studyType,
  downgradeAuthorized,
  configuredDuration,
  recordedDuration
) {
  const prescribedDurationInDays = Math.ceil(configuredDuration / 24);
  // For an 8+ day study, there must be more than 168 hours of recorded data
  const underMediumExtendedHolterRequirements = prescribedDurationInDays >= 8 && recordedDuration <= 168;
  // For a 3-7 day study, there must be more than 48 hours of recorded data
  const underLongExtendedHolterRequirements =
    prescribedDurationInDays >= 3 && prescribedDurationInDays <= 7 && recordedDuration <= 48;
  return (
    studyType?.toLowerCase().includes("extendedholter") &&
    downgradeAuthorized === false &&
    (underMediumExtendedHolterRequirements || underLongExtendedHolterRequirements)
  );
}

function useDoesNotMeetExtendedHolterRequirements(
  studyType,
  downgradeAuthorized,
  configuredDuration,
  recordedDuration
) {
  return React.useMemo(
    () =>
      doesNotMeetExtendedHolterRequirements(
        studyType,
        downgradeAuthorized,
        configuredDuration,
        recordedDuration
      ),
    [configuredDuration, recordedDuration, studyType, downgradeAuthorized]
  );
}

export default useDoesNotMeetExtendedHolterRequirements;

/* eslint-disable max-classes-per-file */
class LockedItem {
  constructor(lockedByUser) {
    this.lockedByUser = lockedByUser;
  }

  get message() {
    return `This item is currently being reviewed by ${this.lockedByUser || "another user"}`;
  }
}

class AssignedItem {
  constructor(assignedUserNames) {
    this.assignedUserNames = assignedUserNames;
  }

  /// Public Functions ///

  get message() {
    return `This item is assigned to ${this.userListText}`;
  }

  get userListText() {
    if (this.assignedUserNames.length <= 1) {
      return this._getUserTextSingleUser();
    }
    return this._getUserTextMultipleUsers();
  }

  /// Private Functions ///

  _getUserTextSingleUser() {
    return this.assignedUserNames[0];
  }

  _getUserTextMultipleUsers() {
    const lastIndex = this.assignedUserNames.length - 1;
    const commaOrAnd = (index) => (index < lastIndex ? ", " : " and ");
    return this.assignedUserNames.reduce((combinedText, currentName, index) => {
      return combinedText + commaOrAnd(index) + currentName;
    });
  }
}

/* @ngInject */
export default class UnopenableItemController {
  constructor($scope, Config, $injector) {
    this._$scope = $scope;
    this._$rootScope = $injector.get("$rootScope");
    this.features = Config.features;

    this.$onInit = this._init;
  }

  /// Public Functions ///

  get message() {
    return this.type.message;
  }

  /// Private Functions ///

  _init() {
    switch (this.unopenableType) {
      case "lockedItem":
        this.type = new LockedItem(this.item.lockedByUserFullName);
        break;
      case "assignedItem":
        this.type = new AssignedItem(this.item.assignedUserNames);
        break;
      default:
        throw new Error(`UnopenableItemController initialized with unsupported type: ${this.unopenableType}`);
    }
  }
}

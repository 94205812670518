import angular from "angular";
import ngMaterial from "angular-material";
import uirouter from "@uirouter/angularjs";

import reportItemComponent from "./reportItem.component.js";
import ReportItemController from "./reportItem.controller.js";

export default angular
  .module("app.components.inboxItem.reportItem", [ngMaterial, uirouter])
  // matches <br-report-item>
  .component("brReportItem", reportItemComponent)
  .controller("ReportItemController", ReportItemController).name;

import angular from "angular";

import ecgEventItemComponent from "./ecgEventItem.component.js";
import EcgEventItemController from "./ecgEventItem.controller.js";

export default angular
  .module("app.components.inboxItem.ecgEventItem", [])
  // matches <br-ecg-event-item>
  .component("brEcgEventItem", ecgEventItemComponent)
  .controller("EcgEventItemController", EcgEventItemController).name;
